<template>
  <div class="carousel--promo-image-wrapper">
    <div class="row">
      <div class="col-12 col-md-6 col-lg-4">
        <banner-picture
          :photo="banner.primary_photo.url"
          :photo-alt="banner.listing_title"
        ></banner-picture>
      </div>
      <div class="col-12 col-md-6 col-lg-4 hide--in-mobile">
        <banner-picture
          :photo="randomPhotoOne.url"
          :photo-alt="banner.listing_title"
          v-if="randomPhotoOne"
        ></banner-picture>
      </div>
      <div class="col-12 col-md-6 col-lg-4 hide--in-mobile hide--in-tablet">
        <banner-picture
          :photo="randomPhotoTwo.url"
          :photo-alt="banner.listing_title"
          v-if="randomPhotoTwo"
        ></banner-picture>
      </div>
    </div>
  </div>
</template>

<script>
import HelperMixin from '@/mixins/helpers';
const BannerPicture = () => import('@/components/home/partials/banner-picture');
export default {
  mixins: [HelperMixin],
  components: {
    BannerPicture,
  },
  props: {
    banner: {
      default: null,
      require: true,
    },
  },
  data: () => ({
    randomPhotoOne: null,
    randomPhotoTwo: null,
  }),
  mounted() {
    let length = this.banner.photos.length;
    let photos = JSON.parse(JSON.stringify(this.banner.photos));
    if (length === 1) {
      this.randomPhotoOne = photos[0];
      this.randomPhotoTwo = photos[0];
    } else if (length === 2) {
      this.randomPhotoOne = photos[0];
      this.randomPhotoTwo = photos[1];
    } else if (length === 3) {
      let self = this;
      photos = photos.filter(function(photo) {
        return photo.id !== self.banner.primary_photo.id;
      });
      this.randomPhotoOne = photos[0];
      this.randomPhotoTwo = photos[1];
    } else {
      let self = this;
      photos = photos.filter(function(photo) {
        return photo.id !== self.banner.primary_photo.id;
      });
      this.randomPhotoOne = this.getRandomPhoto(photos);
      photos = photos.filter(function(photo) {
        return photo.id !== self.randomPhotoOne.id;
      });
      this.randomPhotoTwo = this.getRandomPhoto(photos);
    }
  },
  methods: {},
};
</script>
